import "./styles.scss";

const LoadingScreen = () => {
    return (
        <div className="loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    );
};
export default LoadingScreen;