import RouterModule from './modules/RouterModule';

function App() {
  return (
    <main>
      <RouterModule />
    </main>
  );
}

export default App;
