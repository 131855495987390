import { Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import LoadingScreen from '../../components/LoadingScreen';

const Home = lazy(() => import("../Home"));
const About = lazy(() => import("../About"));

const RouterModule = () => {
    return (
        <Routes>
            <Route path='/' element={<Suspense fallback={<LoadingScreen />}><Home /></Suspense>} />
            <Route path='about' element={<Suspense fallback={<LoadingScreen />}><About /></Suspense>} />
        </Routes>
    )
};

export default RouterModule;